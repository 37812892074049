import { combineReducers } from "redux";
import loadingReducer from "./loading/loadingReducer";
import { testimonialReducer } from "./testimonials/testimonialsReducer";
import { categoryReducer } from "./category/categoryReducer";
import { loanReducer } from "./loan/loanReducer";
import { blogReducer } from "./blog/blogReducer";
import { bannerReducer } from "./banner/bannerReducer";
import { formReducer } from "./form/formReducer";
import { popupReducer } from "./popup/popupReducer";
import { companyReducer } from "./company/companyReducer";

export const reducers = combineReducers({
  // auth
  loadingReducer: loadingReducer,
  testimonialReducer: testimonialReducer,
  categoryReducer: categoryReducer,
  loanReducer: loanReducer,
  blogReducer: blogReducer,
  bannerReducer: bannerReducer,
  formReducer: formReducer,
  popupReducer: popupReducer,
  companyReducer: companyReducer,
});
