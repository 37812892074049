export const adminTypes = {
  // TESTIMONIALS data
  GET_TESTIMONIALS_DATA_PENDING: "GET_TESTIMONIALS_DATA_PENDING",
  GET_TESTIMONIALS_DATA_SUCCESS: "GET_TESTIMONIALS_DATA_SUCCESS",
  GET_TESTIMONIALS_DATA_FAILED: "GET_TESTIMONIALS_DATA_FAILED",

  // TESTIMONIALS data
  GET_CATEGORY_DATA_PENDING: "GET_CATEGORY_DATA_PENDING",
  GET_CATEGORY_DATA_SUCCESS: "GET_CATEGORY_DATA_SUCCESS",
  GET_CATEGORY_DATA_FAILED: "GET_TESTIMONIALS_DATA_FAILED",

  // LOAN data
  GET_LOAN_DATA_PENDING: "GET_LOAN_DATA_PENDING",
  GET_LOAN_DATA_SUCCESS: "GET_LOAN_DATA_SUCCESS",
  GET_LOAN_DATA_FAILED: "GET_LOAN_DATA_FAILED",

  // LOAN Detail data
  GET_LOAN_LIST_DETAIL_PENDING: "GET_LOAN_LIST_DETAIL_PENDING",
  GET_LOAN_LIST_DETAIL_SUCCESS: "GET_LOAN_LIST_DETAIL_SUCCESS",
  GET_LOAN_LIST_DETAIL_FAILED: "GET_LOAN_LIST_DETAIL_FAILED",

  // Blog data
  GET_BLOG_DATA_PENDING: "GET_BLOG_DATA_PENDING",
  GET_BLOG_DATA_SUCCESS: "GET_BLOG_DATA_SUCCESS",
  GET_BLOG_DATA_FAILED: "GET_BLOG_DATA_FAILED",

  // Blog Detail data
  GET_BLOG_DETAIL_PENDING: "GET_BLOG_DETAIL_PENDING",
  GET_BLOG_DETAIL_SUCCESS: "GET_BLOG_DETAIL_SUCCESS",
  GET_BLOG_DETAIL_FAILED: "GET_BLOG_DETAIL_FAILED",

  // Category data
  GET_BLOG_CATEGORY_DATA_PENDING: "GET_BLOG_CATEGORY_DATA_PENDING",
  GET_BLOG_CATEGORY_DATA_SUCCESS: "GET_BLOG_CATEGORY_DATA_SUCCESS",
  GET_BLOG_CATEGORY_DATA_FAILED: "GET_BLOG_CATEGORY_DATA_FAILED",

  // LOAN Detail data
  GET_PERSONAL_DETAIL_PENDING: "GET_PERSONAL_DETAIL_PENDING",
  GET_PERSONAL_DETAIL_SUCCESS: "GET_PERSONAL_DETAIL_SUCCESS",
  GET_PERSONAL_DETAIL_FAILED: "GET_PERSONAL_DETAIL_FAILED",

  //Banner
  GET_BANNER_LIST_PENDING: "GET_BANNER_LIST_PENDING",
  GET_BANNER_LIST_SUCCESS: "GET_BANNER_LIST_SUCCESS",
  GET_BANNER_LIST_FAILED: "GET_BANNER_LIST_FAILED",

  //Banner
  GET_LOAN_CAT_DATA_PENDING: "GET_LOAN_CAT_DATA_PENDING",
  GET_LOAN_CAT_DATA_SUCCESS: "GET_LOAN_CAT_DATA_SUCCESS",
  GET_LOAN_CAT_DATA_FAILED: "GET_LOAN_CAT_DATA_FAILED",

  //Banner
  GET_FORM_DATA_PENDING: "GET_FORM_DATA_PENDING",
  GET_FORM_DATA_SUCCESS: "GET_FORM_DATA_SUCCESS",
  GET_FORM_DATA_FAILED: "GET_FORM_DATA_FAILED",

  //GET_FINICAL
  GET_FINICAL_PENDING: "GET_FINICAL_PENDING",
  GET_FINICAL_SUCCESS: "GET_FINICAL_SUCCESS",
  GET_FINICAL_FAILED: "GET_FINICAL_FAILED",
  //Popup
  GET_POPUP_DATA_PENDING: "GET_POPUP_DATA_PENDING",
  GET_POPUP_DATA_SUCCESS: "GET_POPUP_DATA_SUCCESS",
  GET_POPUP_DATA_FAILED: "GET_POPUP_DATA_FAILED",

  //Company
  GET_COMPANY_DATA_PENDING: "GET_COMPANY_DATA_PENDING",
  GET_COMPANY_DATA_SUCCESS: "GET_COMPANY_DATA_SUCCESS",
  GET_COMPANY_DATA_FAILED: "GET_COMPANY_DATA_FAILED",

  GET_MANAGED_LOAN_DETAIL_PENDING: "GET_MANAGED_LOAN_DETAIL_PENDING",
  GET_MANAGED_LOAN_DETAIL_SUCCESS: "GET_MANAGED_LOAN_DETAIL_SUCCESS",
  GET_MANAGED_LOAN_DETAIL_FAILED: "GET_MANAGED_LOAN_DETAIL_FAILED",
};
